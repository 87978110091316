import React from 'react';
import styled from '@emotion/styled';
import { color, breakpoints, border, area } from '../../../utils/style';
import telephone from '../../../img/telephone.svg';
import deposit from '../../../img/deposit.svg';
import username from '../../../img/username.svg';
import arrow from '../../../img/arrow.svg';
import { reversesMq } from '../../../utils/mediaQuery';
import StepsArrow from '../../../icons/svg/StepsArrow';
import { validateLink } from '../../../utils/system';

const WrapperSection = styled('div')`
  height: 112px;
  display: flex;
  border-radius: ${border.borderRadius4};
  margin-bottom: ${area.betweenBlocks};
  overflow: hidden;
  position: relative;
  @media (max-width: ${breakpoints.xs}) {
    margin-bottom: 0;
  }
  @keyframes move {
    from {
      left: 0;
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    to {
      left: 90%;
      opacity: 0;
    }
  }
  .dynamic-arrow {
    pointer-events: none;
    ${reversesMq('tl')} {
      position: absolute;
      z-index: 1;
      animation-name: move;
      animation-duration: 4s;
      animation-delay: 2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &:after {
      }

      & > svg {
        & > path {
          opacity: 0.8 !important;
        }
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    background: none;
    display: block;
    width: 100%;
    height: auto;
    // padding: 0px 15px;

    & .dynamic-arrow {
      display: none;
    }
  }
`;

const Step = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  width: 33%;
  font-size: 24px;
  font-weight: 100;
  &:nth-of-type(n) {
    width: calc(100% / 3);
    background-color: ${color.bunting};
  }
  
  & .iconImage {
    pointer-events: none;
  }
  
  & > a {
    cursor: pointer;
    
    &:hover {
      & > .wrapper-text {
        & > div {
          & > div, & > span {
            text-shadow: 0 0 15px #0090ff;
          }
        }
      }
    }
  }

  .stepBottomArrow {
    height: 20px;
    width: 20px;
    top: 69%;
    position: absolute;
    transform: rotate(45deg);
    right: 30px;
    border: 15px solid transparent;
    border-radius: 0 0 8px 0;
    border-bottom: 15px solid ${color.bunting};
    box-shadow: 8px 8px 8px -6px black;
    border-right: 15px solid ${color.bunting};
    z-index: 2;
  }

  @media (min-width: ${breakpoints.md}) {
    .stepBottomArrow {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    box-shadow: -3px -9px 8px -4px #000;
    &:nth-of-type(1) {
      border-radius: ${border.borderRadius4} ${border.borderRadius4} 0 0;
    }
    &:nth-of-type(3) {
      border-radius: 0 0 ${border.borderRadius4} ${border.borderRadius4};
    }
    &:nth-of-type(n) {
      .arrowImage {
        display: none;
      }
      width: 100%;
    }
  }

  .step {
    display: flex;
    width: 100%;
    height: 90px;
    margin-left: 31px;
    justify-content: center;
    align-items: center;
    z-index: 1;
    @media (max-width: ${breakpoints.md}) {
      justify-content: space-between;
      align-items: center;
      width: 92%;
    }
    @media (max-width: ${breakpoints.sm}) {
      padding: 12px 0;
    }
    .circle {
      margin-right: 40px;
      padding-top: 5px;
      width: 56px;
      min-width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: #5664a3;
      text-align: center;
    }

    .h2 {
      margin-bottom: 0;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
      letter-spacing: normal; 
    }

    .wrapper-text {
      display: flex;
      flex-direction: column;
      span:nth-of-type(2) {
        font-weight: 100;
      }
    }
  }

  .step1 {
    align-items: center;
    justify-content: space-between;
    .h2 {
      width: 120px;
    }
    .contacts {
      position: relative;
      img {
        width: 60px;
      }
      a {
        border: none;
      }
      a:nth-of-type(2) {
        background-color: #00b900;
        span {
          color: ${color.white};
        }
        svg {
          path {
            fill: ${color.white}!important;
          }
        }
      }
    }

    &:after {
      border-color: transparent transparent transparent #4253a2;
      @media (max-width: ${breakpoints.md}) {
        border-color: #4253a2 transparent transparent transparent;
      }
    }
  }
  
  .step3 {
    @media (max-width: ${breakpoints.md}) {
      flex-direction: row-reverse;
      .circle {
        margin-right: 0;
      }
    }

    .img3 {
      @media (min-width: ${breakpoints.tl}) {
        min-width: 62px;
        min-height: 61px;
      }
    }
  }

  .step2 {

    .img {
      @media (max-width: ${breakpoints.sm}) {
        margin-right: 26px;
        max-width: 30px;
      }
      @media (min-width: ${breakpoints.tl}) {
        min-width: 44px;
        min-height: 59px;
      }
    }

    .img2 {
      @media (min-width: ${breakpoints.tl}) {
        min-width: 73px;
        min-height: 68px;
      }
    }
    @media (max-width: ${breakpoints.md}) {
      flex-direction: row-reverse;
      .circle {
        margin-right: 0;
      }
    }
    &:after {
      border-color: transparent transparent transparent ${color.middlelightPurple};
      @media (max-width: ${breakpoints.md}) {
        border-color: ${color.middlelightPurple} transparent transparent transparent;
      }
    }
  }

  .h2 {
    text-transform: uppercase;
  }

  .contacts {
    @media (max-width: ${breakpoints.sm}) {
      display: flex;
      justify-content: flex-end;
      margin-left: 0;
      height: 100%;
      width: 100%;
      a {
        height: 100%;
        width: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
      a:nth-of-type(1) {
        @media (max-width: ${breakpoints.sm}) {
          background-color: ${color.pink};
        }
      }
      a:nth-of-type(1) {
        margin: 0 8px;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    & .second {
      & > img {
        width: 43px;
        margin-right: 20px;
      }
    }
    
    & .step3 {
      & > img {
        width: 41px;
        margin-right: 21px;
      }
    }
  }
}
`;
const PreSteps = styled('span')`
  font-size: 21px;
  text-transform: uppercase;
  color: ${color.white};
  position: absolute;
  margin: 0 0 0 31px;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.tl}) {
    display: none;
  }
`;

const SliderLine = styled('span')`
  width: 128px;
  height: 21px;
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${color.moonRaker};
`;

const SliderPhone = styled('div')`
  width: 152px;
  height: 30px;
  color: ${color.white};
  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;

  @media (max-width: ${breakpoints.md}) {
    color: ${color.lightningYellow};
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 20px;
  }
`;

const FirstSliderWrapper = styled('div')`
  padding: 0;
  margin-right: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const Steps = ({ step1, step2, step3 }) => (
  <WrapperSection>
    <Step>
      <a href={validateLink(step1.step_1_url)} className="step step2">
        <PreSteps>
          Apply
          <br />
          for:
        </PreSteps>
        <img className="iconImage img" src={telephone} alt="telephone" />
        <div className="wrapper-text">
          <FirstSliderWrapper>
            <SliderPhone>02 508 87 98</SliderPhone>
            <SliderLine>LINE: Lnwasiagroup</SliderLine>
          </FirstSliderWrapper>
        </div>
      </a>
      <img
        style={{
          position: 'absolute',
          right: '-7px',
          bottom: '0',
        }}
        className="iconImage arrowImage"
        src={arrow}
        alt="arrow"
      />
      <span className="iconImage stepBottomArrow" />
    </Step>
    <Step>
      <a href={validateLink(step2.step_2_url)} className="step step2 second">
        <img className="iconImage img2" src={deposit} alt="deposit" />
        <div className="wrapper-text">
          <FirstSliderWrapper>
            <SliderPhone>ฝากเงิน</SliderPhone>
            <SliderLine>พร้อมรับโบนัส</SliderLine>
          </FirstSliderWrapper>
        </div>
      </a>
      <img
        style={{
          position: 'absolute',
          right: '-7px',
          bottom: '0',
        }}
        className="iconImage arrowImage"
        src={arrow}
        alt="arrow"
      />
      <span className="iconImage stepBottomArrow" />
    </Step>
    <Step>
      <a href={validateLink(step3.step_3_url)} className="step step3">
        <img className="img3" src={username} alt="username" />
        <div className="wrapper-text">
          <FirstSliderWrapper>
            <SliderPhone>รับ username</SliderPhone>
            <SliderLine>และรหัสผ่าน</SliderLine>
          </FirstSliderWrapper>
        </div>
      </a>
    </Step>
    <div className="dynamic-arrow">
      <StepsArrow />
    </div>
  </WrapperSection>
);

export default Steps;
