import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import RecentPosts from '../components/Content/RecentPosts';
import Steps from '../components/Content/Home/Steps';
import Games from '../components/Content/Home/Games';
import { breakpoints, Slick } from '../utils/style';
import SlickSlide from '../components/shared/SlickSlide';
import IcomoonIcon from '../components/shared/IcomoonIcon';
import HomePageContext from '../components/shared/Context/HomePageContext';
import PlayForFree from '../components/Content/Home/PlayForFree';
import CommentsStaticLoadmore from '../components/Content/Withdraw/CommentsStaticLoadmore';
import { isMobile } from '../utils/system';
import SBOSBA from '../components/shared/SBOSBA';

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick} aria-label="Next">
      <IcomoonIcon icon="arrow-right" color="#FFF" size={20} alt="arrow-right" />
    </button>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick} aria-label="Previous">
      <IcomoonIcon icon="arrow-left" color="#FFF" size={20} alt="arrow left" />
    </button>
  );
};

const SlickWrapper = styled('div')`
  ${Slick};
  @media (max-width: ${breakpoints.md}) {
    max-width: calc(100vw - 32px);
    margin-bottom: 0;

    & .gatsby-image-wrapper {
      border-radius: 4px;
    }
  }
`;

// REFACTOR
// Spread, maybe something more
const HomePage = ({ data }) => {
  const [resolution, setResolution] = useState(false);
  const onResize = () => {
    setResolution(isMobile(500));
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const homePageNode = data.allWordpressPage.edges[0].node;
  const featuredMedia = homePageNode.featured_media;
  const metaTitle =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_title
      ? homePageNode.yoast_meta.yoast_wpseo_title
      : homePageNode.title + process.env.SITE_NAME_SHORT;
  const metaDesc =
    homePageNode.yoast_meta && homePageNode.yoast_meta.yoast_wpseo_metadesc
      ? homePageNode.yoast_meta.yoast_wpseo_metadesc
      : '';
  const slider = resolution ? homePageNode.acf.top_mobile_slider : homePageNode.acf.top_slider;
  const steps = homePageNode.acf;

  const showSlider = slider ? slider.length > 0 : false;

  const showPlayForFree = true;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [mode, setMode] = useState('home');
  return (
    <HomePageContext.Provider key="home-context-provider" value={{ setMode }}>
      <Layout>
        <SEO title={metaTitle} description={metaDesc} showSchema />
        {mode === 'home' && (
          <div id="home-content-all">
            {showSlider && (
              <SlickWrapper>
                <Slider {...sliderSettings}>
                  {slider?.map((item) => (
                    <SlickSlide
                      type="button"
                      key={item.path}
                      img={item.localFile}
                      altText={item.alt_text}
                      line1={item.alt_text}
                      line2={item.title}
                      link={item.acf.slide_url}
                      ext={!!~item?.acf?.slide_url?.indexOf('http')}
                    />
                  ))}
                </Slider>
              </SlickWrapper>
            )}
            {!showSlider && featuredMedia && featuredMedia.localFile && (
              <div>
                <Img fluid={featuredMedia.localFile.childImageSharp.fluid} alt={homePageNode.featured_media.alt_text} />
              </div>
            )}

            <Steps step1={steps} step2={steps} step3={steps} />

            {showPlayForFree && <PlayForFree />}
            {/* <Prediction /> */}
            <Games />
            <SBOSBA />
            <RecentPosts />
            <CommentsStaticLoadmore />
          </div>
        )}
      </Layout>
    </HomePageContext.Provider>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query {
    allWordpressPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          title
          content
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          acf {
            step_1_url
            step_2_url
            step_3_url
            step_1_title
            step_2_title
            step_3_title
            top_slider {
              alt_text
              title
              path
              acf {
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 430) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            top_mobile_slider {
              alt_text
              title
              path
              acf {
                slide_url
              }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            payment_systems {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
          }
        }
      }
    }

    allWordpressFreespinWithdrawcommentsfirst {
      edges {
        node {
          id
          emoji
          name
          rating
          request_time
          wordpress_id
          comment
        }
      }
    }
  }
`;
